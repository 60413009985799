@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  border: 1px solid red;
} */

body {
  overflow-x: hidden;
}

.scratch-card-container {
  touch-action: none;
}

@layer base {
  .font-outline-1-white {
    -webkit-text-stroke: 0.9px white;
  }
  .font-outline-2-white {
    -webkit-text-stroke: 2px white;
  }
  .font-outline-4-white {
    -webkit-text-stroke: 4px white;
  }

  .font-outline-4-orange {
    -webkit-text-stroke: 13px orangered;
  }

  .font-outline-white {
    text-shadow: 2.5px 0 0 white, 0 2.5px 0 white, -2.5px 0 0 white,
      0 -2.5px 0 white;
  }

  .font-outline-white-large {
    text-shadow: 3px 0 0 white, 0 2.5px 0 white, -2.5px 0 0 white,
      0 -2.5px 0 white;
  }
}

.layout-game {
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
}
.layout-game::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.btn {
  min-height: 2rem !important;
}

@keyframes fadeInAndOut {
  0% {
    bottom: 0;
    opacity: 1;
  }
  50% {
    bottom: 20px;
    opacity: 1;
  }
  100% {
    bottom: 40px;
    opacity: 0;
  }
}

.unreset {
  color: #121212;
  font-size: 13px !important;
  line-height: 1.42 !important;
}

.unreset * {
  margin: 0 !important;
}

.unreset h1 {
  font-size: 2em !important;
  font-weight: bold;
}
.unreset h2 {
  font-size: 1.5em !important;
  font-weight: bold;
}
.unreset h3 {
  font-size: 1.17em !important;
  font-weight: bold;
}

.unreset ul,
.unreset menu {
  list-style: disc; /* list-disc */
  margin-top: 1rem; /* my-4 */
  margin-bottom: 1rem;
  padding-left: 2.5rem; /* pl-10 */
}

.unreset ol {
  list-style: decimal; /* list-decimal */
  margin-top: 1rem; /* my-4 */
  margin-bottom: 1rem;
  padding-left: 2.5rem; /* pl-10 */
}

/* Nested Lists */
.unreset ul ul,
.unreset ul ol,
.unreset menu ul,
.unreset menu ol {
  list-style-type: circle;
}

.unreset ul ul ul,
.unreset ul ul ol,
.unreset ul ol ul,
.unreset ul ol ol,
.unreset menu ul ul,
.unreset menu ul ol,
.unreset menu ol ul,
.unreset menu ol ol {
  list-style-type: square;
}

/* List Items Inside Paragraphs */
.unreset li > p {
  margin-top: 0; /* my-0 */
}

/* Definition Lists */
.unreset dd {
  padding-left: 2.5rem; /* pl-10 */
}

.unreset dl {
  margin-top: 1rem; /* my-4 */
  margin-bottom: 1rem;
}

/* Remove Margins for Nested Lists, Menus, and Definition Lists */
.unreset ul ul,
.unreset ul ol,
.unreset menu ul,
.unreset menu ol,
.unreset dl ul,
.unreset dl ol {
  margin: 0;
}

/* Bold Text */
.unreset b,
.unreset strong {
  font-weight: bold;
}

.custom-container a {
  color: blue;
}
